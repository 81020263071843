/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 cat.glb --transform 
Files: cat.glb [13.01MB] > C:\code\personal-portfolio\public\cat\cat-transformed.glb [6.71MB] (48%)
*/

import React, { useEffect, useState } from "react";
import { useFrame, useGraph } from "@react-three/fiber";
import { useGLTF, useAnimations } from "@react-three/drei";
import { SkeletonUtils } from "three-stdlib";
import * as THREE from "three";
import { useStore } from "../../utils/store";
import { useResponsive } from "../../../customHooks/useResponsive";
import { Responsive } from "../../../constructor/Responsive";

export function Cat({
  desktopSize,
  desktopPosition,
  mobileSize,
  mobilePosition,
}) {
  const cat = React.useRef();
  const { scene, animations } = useGLTF("/cat/cat-transformed.glb");
  const clone = React.useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes, materials } = useGraph(clone);
  const { actions, mixer } = useAnimations(animations, cat);
  const cameraHeight = useStore((state) => state.cameraHeight);
  const [activateAnimation, setActivateAnimation] = useState(false);
  const [showCat, setShowCat] = useState(true);
  const [showCatCount, setShowCatCount] = useState(1);
  const [currentAnimation, SetCurrentAnimation] = useState(
    "Cat scratching 1-143_GRT_"
  );

  const responsiveData = new Responsive();
  responsiveData.desktopSize = desktopSize;
  responsiveData.desktopPositionX = desktopPosition[0];
  responsiveData.desktopPositionY = desktopPosition[1];
  responsiveData.desktopPositionZ = desktopPosition[2];

  responsiveData.mobileSize = mobileSize;
  responsiveData.mobilePositionX = mobilePosition[0];
  responsiveData.mobilePositionY = mobilePosition[1];
  responsiveData.mobilePositionZ = mobilePosition[2];

  const { size, positionX, positionY, positionZ } =
    useResponsive(responsiveData);

  useEffect(() => {
    if (currentAnimation === "Cat scratching 1-143_GRT_") {
      actions[currentAnimation].clampWhenFinished = true;
      actions?.[currentAnimation].setLoop(THREE.LoopOnce);
    }

    if (activateAnimation === true) {
      actions?.[currentAnimation].reset().play();
    }

    return () => {
      actions[currentAnimation]?.fadeOut(0);
    };
  }, [actions, currentAnimation, activateAnimation]);

  mixer.addEventListener("finished", () => {
    SetCurrentAnimation("Cat _Run 1_16_GRT_");
  });

  useFrame(() => {
    if (
      currentAnimation === "Cat _Run 1_16_GRT_" &&
      cat.current.position.z > -45
    ) {
      cat.current.position.z = cat.current.position.z - 0.15;
    } else if (cat.current.position.z < -45 && showCatCount <= 2) {
      setShowCatCount((showCatCount) => showCatCount + 1);
      SetCurrentAnimation("Cat scratching 1-143_GRT_");
      if (showCatCount >= 2) {
        setShowCat(false);
      } else {
        cat.current.position.z = positionZ;
      }
    }
  });

  useEffect(() => {
    if (cameraHeight <= -160) {
      setActivateAnimation(true);
    }

    // used to reset the cat
    if ((cameraHeight > -140 || cameraHeight < -190) && showCat === false) {
      setActivateAnimation(false);
      setShowCatCount(1);
      setShowCat(true);
      cat.current.position.z = positionZ;
    }
  }, [cameraHeight]);

  materials.cat_body.transparent = false;
  materials.cat_body.depthWrite = true;
  materials["cat_fur_.001"].transparent = false;
  materials["cat_fur_.001"].depthWrite = true;

  return (
    <group
      ref={cat}
      position={[positionX, positionY, positionZ]}
      dispose={null}
      visible={showCat}
    >
      <group name="Scene" scale={size} rotation={[-1.57, 0, 3.6]}>
        <group name="Object_5">
          <primitive object={nodes["DEF-spine_02"]} />
          <primitive object={nodes["DEF-tail006_0184"]} />
        </group>

        <skinnedMesh
          name="Object_199"
          geometry={nodes.Object_199.geometry}
          material={materials.cat_body}
          skeleton={nodes.Object_199.skeleton}
          rotation={[-Math.PI / 2, 0, -Math.PI]}
        />
        <skinnedMesh
          name="Object_197"
          geometry={nodes.Object_197.geometry}
          material={materials["cat_fur_.001"]}
          skeleton={nodes.Object_197.skeleton}
          rotation={[-Math.PI / 2, 0, 0]}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/cat/cat-transformed.glb");
