/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 i-pad.glb --transform 
Files: i-pad.glb [2.2MB] > C:\code\personal-portfolio\public\ipad\ipad_custom\i-pad-transformed.glb [156.3KB] (93%)
*/

import React, { useEffect, useRef, useState } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { DoubleSide } from "three";
import { Responsive } from "../../../../constructor/Responsive";
import { useResponsive } from "../../../../customHooks/useResponsive";

export function Ipad({
  experienceImage,
  desktopSize,
  desktopPosition,
  mobileSize,
  mobilePosition,
}) {
  const { nodes, materials } = useGLTF("/ipad/i-pad-transformed.glb");
  const mat = useRef();
  let loadedTexture = useTexture(experienceImage);

  // DONT REMOVE - forceMat is work around to force a rerender to get the mat ref to display on the Ipad
  const [forceMat, setForceMat] = useState(false);
  useEffect(() => {
    setForceMat(true);
  }, []);

  const responsiveData = new Responsive();
  responsiveData.desktopSize = desktopSize;
  responsiveData.desktopPositionX = desktopPosition[0];
  responsiveData.desktopPositionY = desktopPosition[1];
  responsiveData.desktopPositionZ = desktopPosition[2];

  responsiveData.mobileSize = mobileSize;
  responsiveData.mobilePositionX = mobilePosition[0];
  responsiveData.mobilePositionY = mobilePosition[1];
  responsiveData.mobilePositionZ = mobilePosition[2];

  const { size, positionX, positionY, positionZ } =
    useResponsive(responsiveData);

  return (
    <>
      <group
        scale={size}
        position={[positionX, positionY, positionZ]}
        rotation={[-1.57, -1.57, -1.57]}
        dispose={null}
      >
        <mesh
          geometry={nodes.Apple_logo_cameraframe_and_logo_0.geometry}
          material={materials.PaletteMaterial001}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <mesh
          geometry={nodes["camera1_camera1(2)_0"].geometry}
          material={materials.camera12}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <mesh
          geometry={nodes.camera_glass_0.geometry}
          material={materials.PaletteMaterial002}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <mesh
          geometry={nodes.camera_module2001_Camera_Flash_0.geometry}
          material={materials.Camera_Flash}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <mesh
          geometry={nodes.camera_module2001_Mic_0.geometry}
          material={materials.material}
          rotation={[-Math.PI / 2, 0, 0]}
          scale={100}
        />
        <meshBasicMaterial side={DoubleSide} map={loadedTexture} ref={mat} />
        {mat.current && (
          <mesh
            geometry={nodes.iPad_Pro_2020_screen_0.geometry}
            material={mat.current}
            position={[0, 77.5, 0]}
            rotation={[1.57, 0, Math.PI]}
            scale={100}
          />
        )}
      </group>
    </>
  );
}

useGLTF.preload("/ipad/i-pad-transformed.glb");
