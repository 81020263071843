/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF, useTexture } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useStore } from "../../utils/store";

export default function GithubLogo() {
  const logo = useRef();
  const { nodes } = useGLTF("/logos/github-3d-logo-transformed.glb");

  useFrame(({ clock }) => {
    const ticks = clock.getElapsedTime();

    logo.current.rotation.z = ticks / 3;
  });

  let loadedTexture = useTexture("/textures/purple_08_matCap.png");
  let loadedTextureTwo = useTexture("/textures/blue_09_matCap.png");
  const isDesktop = useStore((state) => state.isDesktop);

  return (
    <group
      ref={logo}
      position={[10, 8, 20]}
      rotation={[1.5708, 0, 0]}
      dispose={null}
      scale={17}
      visible={isDesktop}
    >
      <mesh geometry={nodes.Curve.geometry}>
        <meshMatcapMaterial matcap={loadedTexture} />
      </mesh>
      <mesh geometry={nodes.Curve001.geometry}>
        <meshMatcapMaterial matcap={loadedTextureTwo} />
      </mesh>
    </group>
  );
}

useGLTF.preload("/logos/github-3d-logo-transformed.glb");
