/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 matrix.glb --transform 
Files: matrix.glb [990.14KB] > C:\code\personal-portfolio\public\matrix\matrix-transformed.glb [129.82KB] (87%)
*/

import React, { useEffect, useState } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useStore } from "../../../utils/store";

const matrixPartialHeightStart = -90;
const matrixPartialHeightEnd = -208;

export function Matrix({ animationSpeed }) {
  const matrixRef = React.useRef();
  const { nodes, materials, animations } = useGLTF(
    "/matrix/matrix-transformed.glb"
  );
  const { actions } = useAnimations(animations, matrixRef);

  const [showPartialMatrix, setShowPartialMatrix] = useState(false);
  const cameraHeight = useStore((state) => state.cameraHeight);
  useEffect(() => {
    if (
      cameraHeight >= matrixPartialHeightStart ||
      cameraHeight <= matrixPartialHeightEnd
    ) {
      setShowPartialMatrix(false);
    } else {
      setShowPartialMatrix(true);
    }
  }, [cameraHeight]);

  if (actions?.Animation?.timeScale) {
    actions.Animation.timeScale = animationSpeed;
  }
  useEffect(() => {
    actions?.Animation.play();
  });

  return (
    <group
      scale={[18, 18, 18]}
      position={[0, -60, 0]}
      ref={matrixRef}
      dispose={null}
    >
      <group name="Sketchfab_Scene">
        <group name="GLTF_SceneRootNode">
          <group
            visible={showPartialMatrix}
            name="color003_19"
            position={[-1.883, 5.151, -0.867]}
            rotation={[0, 1.205, 0]}
          >
            <mesh
              name="Object_4"
              geometry={nodes.Object_4.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color004_20"
            position={[0.831, 3.321, 1.612]}
            rotation={[Math.PI, -0.53, Math.PI]}
          >
            <mesh
              name="Object_6"
              geometry={nodes.Object_6.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color011_21"
            position={[1.075, 5.151, -2.485]}
            rotation={[0, -0.41, 0]}
          >
            <mesh
              name="Object_8"
              geometry={nodes.Object_8.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color012_22"
            position={[1.581, 3.321, -0.23]}
            rotation={[0, -1.519, 0]}
          >
            <mesh
              name="Object_10"
              geometry={nodes.Object_10.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color013_23"
            position={[-1.237, 3.321, -2.589]}
            rotation={[0, 0.573, 0]}
          >
            <mesh
              name="Object_12"
              geometry={nodes.Object_12.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color017_24"
            position={[2.049, 3.321, 2.098]}
            rotation={[Math.PI, -0.614, Math.PI]}
          >
            <mesh
              name="Object_14"
              geometry={nodes.Object_14.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color021_25"
            position={[-0.07, 3.321, 1.248]}
            rotation={[-Math.PI, 0.112, -Math.PI]}
          >
            <mesh
              name="Object_16"
              geometry={nodes.Object_16.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color029_26"
            position={[0.873, 6.531, 2.497]}
            rotation={[Math.PI, -0.479, Math.PI]}
            scale={1.947}
          >
            <mesh
              name="Object_18"
              geometry={nodes.Object_18.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color030_27"
            position={[-1.954, 4.562, 1.09]}
            rotation={[-Math.PI, 1.005, -Math.PI]}
            scale={1.366}
          >
            <mesh
              name="Object_20"
              geometry={nodes.Object_20.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color031_28"
            position={[2.976, 4.562, 0.135]}
            rotation={[Math.PI, -1.462, Math.PI]}
            scale={1.366}
          >
            <mesh
              name="Object_22"
              geometry={nodes.Object_22.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color032_29"
            position={[0.155, 4.562, -4.27]}
            rotation={[0, 0.083, 0]}
            scale={1.366}
          >
            <mesh
              name="Object_24"
              geometry={nodes.Object_24.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color033_30"
            position={[-2.965, 4.562, 4.437]}
            rotation={[-Math.PI, 0.617, -Math.PI]}
            scale={1.366}
          >
            <mesh
              name="Object_26"
              geometry={nodes.Object_26.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color037_31"
            position={[2.48, 8.471, -1.09]}
            rotation={[0, -1.419, 0]}
            scale={2.52}
          >
            <mesh
              name="Object_28"
              geometry={nodes.Object_28.geometry}
              material={materials.Color}
            />
          </group>

          <group
            visible={showPartialMatrix}
            name="color038_32"
            position={[-3.249, 10.301, -1.991]}
            rotation={[0, 0.714, 0]}
            scale={2.52}
          >
            <mesh
              name="Object_30"
              geometry={nodes.Object_30.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color039_33"
            position={[-3.844, 8.471, 1.664]}
            rotation={[-Math.PI, 1.119, -Math.PI]}
            scale={2.52}
          >
            <mesh
              name="Object_32"
              geometry={nodes.Object_32.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color040_34"
            position={[-2.416, 8.471, -0.104]}
            rotation={[0, 1.565, 0]}
            scale={2.52}
          >
            <mesh
              name="Object_34"
              geometry={nodes.Object_34.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color041_35"
            position={[4.477, 8.471, 1.613]}
            rotation={[Math.PI, -1.157, Math.PI]}
            scale={2.52}
          >
            <mesh
              name="Object_36"
              geometry={nodes.Object_36.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color042_36"
            position={[4.061, 11.672, -2.833]}
            rotation={[0, -1.078, 0]}
            scale={3.465}
          >
            <mesh
              name="Object_38"
              geometry={nodes.Object_38.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={showPartialMatrix}
            name="color043_37"
            position={[-1.631, 11.672, -4.473]}
            rotation={[0, 0.371, 0]}
            scale={3.465}
          >
            <mesh
              name="Object_40"
              geometry={nodes.Object_40.geometry}
              material={materials.Color}
            />
          </group>
          <group
            visible={false} // this stays hidden because it is to much
            name="Cylinder001_49"
            position={[0, -64.018, 0]}
            scale={0.071}
          >
            <mesh
              name="Object_65"
              geometry={nodes.Object_65.geometry}
              material={materials.Color}
            />
          </group>
        </group>
        <mesh
          visible={showPartialMatrix}
          name="Object_68"
          geometry={nodes.Object_68.geometry}
          material={materials.Mask}
          position={[0, 6.993, 0]}
          scale={0.071}
        />
        <mesh
          visible={showPartialMatrix}
          name="Object_70"
          geometry={nodes.Object_70.geometry}
          material={materials.Alpha}
          position={[0, 19.244, 0]}
          scale={8.942}
        />
      </group>
    </group>
  );
}

useGLTF.preload("/matrix/matrix-transformed.glb");
