/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.3 satellite.glb --transform 
Files: satellite.glb [2.58MB] > C:\code\personal-portfolio\public\satellite\satellite-transformed.glb [201.55KB] (92%)
Author: Daan van Leeuwen (https://sketchfab.com/superwortel)
License: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
Source: https://sketchfab.com/3d-models/satellite-d232fe608c874009bad9613c8093a972
Title: Satellite
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";

export function Satellite() {
  const { nodes, materials } = useGLTF("/satellite/satellite-transformed.glb");

  const satellite = useRef();
  useFrame(({ clock }) => {
    // puts satellite in orbit
    satellite.current.position.x = Math.cos(clock.getElapsedTime() / 24) * 20;
    satellite.current.position.y = Math.cos(clock.getElapsedTime() / 24) * 75;
    satellite.current.position.z = Math.sin(clock.getElapsedTime() / 24) * 20;

    satellite.current.rotation.z = Math.cos(clock.getElapsedTime() / 120) * 50;
  });
  return (
    <group position={[60, -60, 0]} dispose={null}>
      <mesh
        ref={satellite}
        geometry={nodes.Object_2.geometry}
        material={materials.lambert1}
        rotation={[-Math.PI / 2, 1.57 / 2, 0]}
      />
    </group>
  );
}

useGLTF.preload("/satellite/satellite-transformed.glb");
